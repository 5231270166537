
import { defineComponent, onMounted, ref } from "vue";
import TransactionHistoryDetailPanel from "@/components/history/TransactionHistoryDetailPanel.vue";
import TransactionService from "@/service/transaction-service/TransactionService";
import { useRoute } from "vue-router";
import { TransactionDetail } from "@/types/transaction/TransactionDetail.interface";

export default defineComponent({
  components: {
    TransactionHistoryDetailPanel,
  },
  setup() {
    const transactionService = ref(new TransactionService());
    const route = useRoute();
    const transaction = ref<TransactionDetail>();

    const getOrderDetailById = async () => {
      await transactionService.value
        .getOrderDetailById(route.params.id.toString())
        .then((response: TransactionDetail) => {
          transaction.value = response;
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    onMounted(() => {
      getOrderDetailById();
    });

    return { transaction };
  },
});
