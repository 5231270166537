
import { computed, defineComponent, onMounted, PropType, ref } from "vue";
import TransactionHeader from "@/components/transaction/transaction-detail/header/TransactionHeader.vue";
import { TransactionDetail } from "@/types/transaction/TransactionDetail.interface";
import TransactionAddressDetail from "@/components/transaction/transaction-detail/address/TransactionAddressDetail.vue";
import TransactionShippingDetail from "@/components/transaction/transaction-detail/cart/TransactionShippingDetail.vue";
import TransactionPaymentDetail from "@/components/transaction/transaction-detail/cart/TransactionPaymentDetail.vue";
import TransactionCartDetail from "@/components/transaction/transaction-detail/cart/TransactionCartDetail.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { TransactionActionTypes } from "@/store/modules/transaction/action-types";

export default defineComponent({
  components: {
    TransactionHeader,
    TransactionAddressDetail,
    TransactionShippingDetail,
    TransactionPaymentDetail,
    TransactionCartDetail,
  },
  props: {
    transaction: {
      required: true,
      type: Object as PropType<TransactionDetail>,
    },
  },
  setup(props) {
    const buttonMessage = ref<string>("กรอกข้อมูล");
    const isDisplayButton = ref<boolean>(false);
    const store = useStore();

    const router = useRouter();
    const route = useRoute();

    const checkTransactonStatus = () => {
      if (props.transaction.statusId == 9) {
        buttonMessage.value = "กรอกข้อมูล";
        isDisplayButton.value = true;
      } else if (props.transaction.statusId == 2) {
        buttonMessage.value = "ชำระเงิน";
        isDisplayButton.value = true;
      } else if (
        props.transaction.statusId == 3 ||
        props.transaction.statusId == 8
      ) {
        buttonMessage.value = "ตรวจสอบการชำระเงิน";
        isDisplayButton.value = true;
      } else {
        isDisplayButton.value = false;
      }
    };

    const goToStatus = async () => {
      await store.dispatch(
        TransactionActionTypes.SET_TRANSACTION,
        props.transaction.id.toString()
      );
      const sessionId = route.query.sessionId?.toString();
      router.push(`/${props.transaction.id}?sessionId=${sessionId}`);
    };

    onMounted(() => {
      checkTransactonStatus();
    });

    return {
      buttonMessage,
      isDisplayButton,
      backgroundColor: computed(() => store.getters.getBrand.colorThemeMain),
      goToStatus,
    };
  },
});
