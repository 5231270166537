
import { TransactionDisplayPromotions } from "@/types/transaction/TransactionPromotion";
import moment from "moment";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    displayPromotions: {
      required: true,
      type: Array as PropType<TransactionDisplayPromotions[]>,
    },
  },
  setup() {
    const datetimeFormat = (data: Date) => {
      return moment(data).format("DD MMM YYYY HH:mm:ss");
    };

    return { datetimeFormat };
  },
});
