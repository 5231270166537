
import { TransactionDetail } from "@/types/transaction/TransactionDetail.interface";
import { defineComponent, PropType, ref } from "vue";
import TransactionPromotion from "./TransactionPromotion.vue";
import TransactionFreeProduct from "./TransactionFreeProduct.vue";

export default defineComponent({
  components: {
    TransactionPromotion,
    TransactionFreeProduct,
  },
  props: {
    transaction: {
      required: true,
      type: Object as PropType<TransactionDetail>,
    },
    isShowCart: {
      required: false,
      type: Boolean,
    },
  },
  setup(props) {
    const isCollapsed = ref<boolean>(true);
    isCollapsed.value = JSON.parse(JSON.stringify(props.isShowCart));
    const freeProductPanel = ref<InstanceType<typeof TransactionFreeProduct>>();

    const refreshData = () => {
      freeProductPanel.value?.refreshData();
    };

    return { isCollapsed, freeProductPanel, refreshData };
  },
});
