
import TransactionService from "@/service/transaction-service/TransactionService";
import { Bank } from "@/types/master/Bank.interface";
import { SlipDetail } from "@/types/transaction/TransactionPayment.interface";
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  props: {
    transactionId: {
      required: true,
      type: Number,
    },
    paymentName: {
      required: true,
      type: String,
    },
    paymentMethodId: {
      required: true,
      type: Number,
    },
    paymentReference: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const transactionService = ref(new TransactionService());
    const bankList = ref<Bank[]>();
    const paymentSlipDetail = ref<SlipDetail>();
    const bank = ref<Bank>();

    const getBankList = async () => {
      await transactionService.value
        .getBankList(props.transactionId)
        .then((response: Bank[]) => {
          bankList.value = response;
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const getPaymentSlipInformation = async () => {
      await transactionService.value
        .getOrderUploadSlip(props.transactionId)
        .then((response: SlipDetail) => {
          paymentSlipDetail.value = response;
          bank.value = bankList.value?.find(
            (el) => el.id == paymentSlipDetail.value?.bankAccountId
          );
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    onMounted(async () => {
      await getBankList();
      await getPaymentSlipInformation();
    });

    return {
      bankList,
      paymentSlipDetail,
      bank,
    };
  },
});
