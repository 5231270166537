import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransactionHistoryDetailPanel = _resolveComponent("TransactionHistoryDetailPanel")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      (_ctx.transaction)
        ? (_openBlock(), _createBlock(_component_TransactionHistoryDetailPanel, {
            key: 0,
            transaction: _ctx.transaction
          }, null, 8, ["transaction"]))
        : _createCommentVNode("", true)
    ])
  ]))
}