
import { defineComponent, PropType, ref } from "vue";
import { BuyerInformation } from "@/types/transaction/TransactionAddress.interface";

export default defineComponent({
  props: {
    buyerInfo: {
      required: true,
      type: Object as PropType<BuyerInformation>,
    },
    isDisplayAddress: {
      required: true,
      type: Boolean,
    },
  },
  setup(props) {
    const isCollapsed = ref<boolean>(true);
    isCollapsed.value = JSON.parse(JSON.stringify(props.isDisplayAddress));
    return { isCollapsed };
  },
});
