
import { TransactionTracking } from "@/types/transaction/TransactionDetail.interface";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    trackingNo: {
      required: true,
      type: Object as PropType<TransactionTracking>,
    },
    shippingTypeId: {
      required: true,
      type: Number,
    },
    shippingTypeName: {
      required: true,
      type: String,
    },
    grandTotal: {
      required: true,
      type: Number,
    },
  },
  setup() {
    return {};
  },
});
